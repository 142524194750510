.bg-color {
  // background-color: #ffe2001c;
}
.app {
  nav {
    padding: 13px 20px 0px 20px;
    ul {
      margin: 0;
      padding: 0;
      list-style-type: none;

      li {
        margin: 0;
        display: inline-block;
        font-size: 18px;
        // font-weight: 100;
        
        a {
          color: #999999;
          text-decoration: none;
          font-style: italic;
        }

        a.active {
          color: #000000;
        }

        span {
          color: #999999;
          font-style: italic;
        }
      }

      .logo {
        color: #000000;
        text-decoration: none;
        font-weight: 500 !important;
        font-style: normal;
      }
    }
  }

  .smooth-image {
    transition: opacity 0.4s ease-out;
  }
  .image-visible {
    opacity: 1;
  }
  .image-hidden {
    opacity: 0;
  }

  .article-holder {
    max-width: 640px;
    font-size: 18px;
    // font-weight: 100;

    a {
      color: black;
    }

    a:hover {
      color:blue;
    }

    .bold {
      font-weight: 500;
    }

    .section-headline {
      font-size: 18px;
      font-style: italic;
    }
  }

  .about-content-holder {
    .article-holder {
      margin: 25px 20px 20px 20px;
    }
  }

  .project-list-holder {
    margin: 33px 20px 20px 20px;

    .thumb {
      a {
        text-decoration: none;
      }

      .img-container {
        padding-bottom: 100%;
        background-color: #f6f6f6;
        position: relative;
      }

      img {
        width: 100%;
        display: inline-block;
        vertical-align: middle;
        max-width: 100%;
        height: auto;
        margin: 0;
        position: absolute;
      }

      h1 {
        font-size: 18px;
        font-weight: 300;
        text-align: center;
        color: black;
        font-style: italic;
        margin: 16px 0px 16px 0px;
        line-height: 1.2;
      }
    }
  }

  .project-detail-page {
    margin: 0px 20px 20px 20px;

    h1 {
      display: inline-block;
      width: auto;
      font-size: 18px;
      font-weight: 300;
      // text-align: center;
      color: black;
      font-style: italic;
      margin: 3px 4px 12px 0px;
      line-height: 1.2;
    }

    nav {
      margin: 0px;
      padding: 0px;
      display: inline-block;

      ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
  
        li {
          margin: 0;
          display: inline-block;
          font-size: 18px;
          // font-weight: 100;
          
          a {
            color: #999999;
            text-decoration: none;
            font-style: italic;
          }
  
          a.active {
            color: #000000;
          }

          span {
            color: #999999;
            font-style: italic;
          }
        }
      }
    }

    .gallery-holder {
      margin-top: 23px;
      max-width: 1400px;

      .inline-description {
        max-width: 640px;
        font-size: 18px;
        // font-style: italic;
        .italic {
          font-style: italic;
        }
      }

      .pair-gallery {
        display: flex;
        gap: 20px 20px;
        margin: 0px 0px 40px 0px;
        @media only screen and (min-width: 0px) and (max-width: 640px) {
          flex-wrap: wrap;
          margin: 0px 0px 20px 0px;
        }
  
        .img-holder {
          // width: 50%;
          display: block;
  
          img {
            vertical-align: middle;
            display: inline-block;
            max-width: 100%;
            // @media only screen and (min-width: 641px) {
            //   max-height: calc(100vh - 40px);
            // }
          }
        }

        video {
          // vertical-align: middle;
          // display: inline-block;
          width: calc(50% - 10px);
          @media only screen and (min-width: 0px) and (max-width: 640px) {
            width: 100%;
          }
        }

        .hidden {
          visibility: hidden;
          @media only screen and (min-width: 0px) and (max-width: 640px) {
            display: none;
          }
        }
      }

      .pair-gallery:last-child {
        margin: 0px 0px 20px 0px;
      }

      .single-gallery {
        display: flex;
        gap: 20px 20px;
        margin: 0px 0px 40px 0px;
        flex-wrap: wrap;
        margin: 0px 0px 40px 0px;
        @media only screen and (min-width: 0px) and (max-width: 640px) {
          flex-wrap: wrap;
          margin: 0px 0px 20px 0px;
        }
  
        .img-holder {
          // width: 50%;
          display: block;
          @media only screen and (min-width: 641px) {
            padding-right: calc(50% + 10px);
          }
  
          img {
            vertical-align: middle;
            display: inline-block;
            max-width: 100%;
            // @media only screen and (min-width: 641px) {
            //   max-height: calc(100vh - 40px);
            // }
          }
        }

        .hidden {
          visibility: hidden;
          @media only screen and (min-width: 0px) and (max-width: 640px) {
            display: none;
          }
        }
      }

      .single-gallery:last-child {
        margin: 0px 0px 20px 0px;
      }

      .fullwidth-gallery {
        display: flex;
        gap: 20px 20px;
        margin: 0px 0px 40px 0px;
        flex-wrap: wrap;
        margin: 0px 0px 40px 0px;
        @media only screen and (min-width: 0px) and (max-width: 640px) {
          flex-wrap: wrap;
          margin: 0px 0px 20px 0px;
        }
  
        .img-holder {
          // width: 50%;
          display: block;
          @media only screen and (min-width: 641px) {
            // padding-right: calc(50% + 10px);
          }
  
          img {
            vertical-align: middle;
            display: inline-block;
            max-width: 100%;
            // @media only screen and (min-width: 641px) {
            //   max-height: calc(100vh - 40px);
            // }
          }
        }

        .hidden {
          visibility: hidden;
          @media only screen and (min-width: 0px) and (max-width: 640px) {
            display: none;
          }
        }
      }

      .fullwidth-gallery:last-child {
        margin: 0px 0px 20px 0px;
      }

    }
  }
}